import { ORG_LOOKUP_API_URL } from "./constants";

export default async function fetchOrganisations() {
  const authenticationKey = sessionStorage.getItem("organisation-api-key");

  const response = await fetch(`${ORG_LOOKUP_API_URL}/organisations`, {
    headers: {
      authorization: authenticationKey ? `Bearer ${authenticationKey}` : "",
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return (await response.json()) as string[];
}
