import { CheckedEnvironment } from "./types";

export function sortOnName(a: CheckedEnvironment, b: CheckedEnvironment) {
  if (a.organisation < b.organisation) {
    return -1;
  }
  if (a.organisation > b.organisation) {
    return 1;
  }
  return 0;
}
