import React, { useEffect, useState } from "react";
import "./App.css";
import primioLogo from "./primio_logo.png";
import { CheckedEnvironment } from "./types";
import { useStatusCheck } from "./hooks";
import { sortOnName } from "./utils";
import { Check, ExclamationMark, HorizontalLine } from "./icons";
import { ORG_LOOKUP_API_URL } from "./constants";

function App() {
  const [hasAccess, setHasAccess] = useState(false);
  const { data, lastCheckedDate, performCheck } = useStatusCheck(hasAccess);

  useEffect(() => {
    if (!hasAccess) return;

    const id = setInterval(performCheck, 30000);
    return () => {
      clearInterval(id);
    };
  }, [hasAccess, performCheck]);

  return (
    <div className="App">
      <div className="App-header">
        <div className={"Container"}>
          <img alt="Primio logo" src={primioLogo} className="App-logo" />
          <div className="Divider-vertical" />
          <span className="App-header--Label">Status</span>
          <div className="Spacer" style={{ width: "100%" }} />
          {lastCheckedDate && (
            <div>
              <span className="App-header--Label">
                Last checked: {lastCheckedDate.toLocaleTimeString()}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="Container App-content">
        {hasAccess && (
          <>
            <OverallStatus environments={data} />
            <Overview environments={data} />
          </>
        )}

        {!hasAccess && <Password onMatch={() => setHasAccess(true)} />}
      </div>
    </div>
  );
}

function Password({ onMatch }: { onMatch: () => void }) {
  const [password, setPassword] = useState<string>("");

  return (
    <div className="Password">
      <input
        type="password"
        placeholder="Password"
        autoComplete="current-password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button
        type="submit"
        title="submit"
        onClick={async () => {
          const response = await fetch(`${ORG_LOOKUP_API_URL}/organisations`, {
            headers: {
              authorization: `Bearer ${password}`,
            },
          });

          if (response.ok) {
            onMatch();
            sessionStorage.setItem("organisation-api-key", password);
          }
        }}
      >
        Submit
      </button>
    </div>
  );
}

function OverallStatus({
  environments,
}: {
  environments: CheckedEnvironment[];
}) {
  const [status, setStatus] = useState<"good" | "warning" | "error">();

  useEffect(() => {
    const offlineEnvironments = environments.filter(
      (e) => e.status === "offline",
    );
    if (offlineEnvironments.length === 0) {
      setStatus("good");
      return;
    }

    if (
      environments.length > 0 &&
      offlineEnvironments.length === environments.length
    ) {
      setStatus("error");
      return;
    }

    setStatus("warning");
  }, [environments]);

  return (
    <div className="Overall">
      <div className={"Overall-icon " + status}>
        {status === "good" && <Check />}
        {status === "warning" && <ExclamationMark />}
        {status === "error" && <HorizontalLine />}
      </div>
      {status === "good" && <h1>Primio is up and running</h1>}
      {status === "warning" && <h1>Primio has some issues</h1>}
      {status === "error" && <h1>Primio is completely down</h1>}
    </div>
  );
}

function Overview({ environments }: { environments: CheckedEnvironment[] }) {
  return (
    <div className="Overview">
      <div className="Overview-header">
        <span>Current status by environment</span>
      </div>
      <div className="Overview-body">
        {environments.sort(sortOnName).map((environment, index) => (
          <Environment environment={environment} key={index} />
        ))}
      </div>
    </div>
  );
}

function Environment({ environment }: { environment: CheckedEnvironment }) {
  const { status, version, organisation } = environment;
  const domain = ".primio.app";
  const apiDocsUrl = `https://api.${environment.organisation}${domain}/docs`;
  const cmsUrl = `https://cms.${environment.organisation}${domain}`;

  return (
    <div className="Environment">
      <div className="Environment-body">
        <p className="Environment-name">{organisation}</p>
        <div className="Environment-info">
          <span>
            <a target="_blank" rel="noreferrer" href={apiDocsUrl}>
              API
            </a>{" "}
            - {status}
            {!!version && <i> ({version})</i>}
          </span>

          <span style={{ marginLeft: 8 }}>
            <a target="_blank" rel="noreferrer" href={cmsUrl}>
              CMS
            </a>
          </span>
        </div>
      </div>
      <div className={"Environment-status " + status} />
    </div>
  );
}

export default App;
