import { useCallback, useEffect, useState } from "react";
import { CheckedEnvironment } from "./types";
import checkStatus from "./check-status";
import fetchOrganisations from "./fetch-organisations";

export function useStatusCheck(enabled: boolean) {
  const [data, setData] = useState<CheckedEnvironment[]>([]);
  const [lastCheckedDate, setLastCheckedDate] = useState<Date>();

  const getOrganisations = async () => await fetchOrganisations();

  const performCheck = useCallback(async () => {
    const organisation = await getOrganisations();

    if (!organisation || organisation.length === 0) {
      return;
    }

    Promise.all(organisation.map(checkStatus)).then((res) => {
      setData(res);
      setLastCheckedDate(new Date());
    });
  }, []);

  useEffect(() => {
    if (!enabled) return;

    performCheck();
  }, [enabled, performCheck]);

  return {
    data,
    lastCheckedDate,
    performCheck,
  };
}
