import { CheckedEnvironment } from "./types";

const checkStatus = (organisation: string) =>
  new Promise<CheckedEnvironment>((resolve) => {
    const domain = ".primio.app";
    const apiUrl = `https://api.${organisation}${domain}`;

    const authenticationKey = sessionStorage.getItem("organisation-api-key");

    fetch(`${apiUrl}/health/`, {
      headers: {
        authorization: authenticationKey ? `Bearer ${authenticationKey}` : "",
      },
    })
      .then((res) => {
        resolve({
          organisation,
          status: res.ok ? "online" : "offline",
          version: res.headers.get("version")?.toString(),
        });
      })
      .catch((error) => {
        resolve({
          organisation,
          status: "offline",
          version: error.message,
        });
      });
  });

export default checkStatus;
